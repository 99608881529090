import "./index.scss";
import React from "react";
import IMAGES from "../../../images/iconSet";
import DeleteItemModal from "../../deleteItemModal/DeleteItemModal";
import VisitNotifyModal from "../VisitNotifyModal";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.tabsBar = React.createRef();
    this.tabsTrack = React.createRef();

    this.state = {
      current: 0,
      count: props.tabs.length,
      isDeleteModalOpen: false,
      visitToDeleteIndex: -1,
      visitNotifyModalOpened: false,
      visitNotifyIndex: -1,
    };
  }

  componentDidUpdate = () => {
    let stateIsChange = false;

    if (this.props.tabs.length !== this.state.count) {
      this.state.count = this.props.tabs.length;
      stateIsChange = true;
    }

    const moveTriggerCount = this.tabsBar.current
      ? parseInt(this.tabsBar.current.offsetWidth / 130) - 2
      : 2;

    if (this.props.activeTab < moveTriggerCount) {
      if (this.state.current !== 0) {
        this.state.current = 0;
        stateIsChange = true;
      }
    } else {
      if (this.props.activeTab - moveTriggerCount !== this.state.current) {
        this.state.current = this.props.activeTab - moveTriggerCount;
        stateIsChange = true;
      }
    }

    if (stateIsChange) this.setState(this.state);
  };

  tabsMove = (direction) => {
    let newPosition = this.props.activeTab + direction;

    if (newPosition < 0) newPosition = 0;
    if (newPosition >= this.state.count) newPosition = this.state.count - 1;

    this.props.onTabsMove(newPosition);
  };

  handleOpenDeleteModal = (index) => {
    this.setState({ visitToDeleteIndex: index, isDeleteModalOpen: true });
  };

  handelCloseModal = () => {
    this.setState({ visitToDeleteIndex: -1, isDeleteModalOpen: false });
  };

  handleDelete = () => {
    this.props.removeVisit(this.state.visitToDeleteIndex);
  };

  handleOpenNotifyModal = (index) => {
    this.setState({ visitNotifyModalOpened: true });
    this.setState({ visitNotifyIndex: index });
  };

  render() {
    return (
      <>
        <VisitNotifyModal
          opened={this.state.visitNotifyModalOpened}
          onClose={() => this.setState({ visitNotifyModalOpened: false })}
          visitIndex={this.state.visitNotifyIndex}
          prevVisitDateItems={this.props.prevVisitDateItems}
        />
        <div className="tabs--nav">
          <div className="tabs--nav--menu" ref={this.tabsBar}>
            <div
              className="tabs--nav--menu--track"
              ref={this.tabsTrack}
              style={{
                transform:
                  "translateX(" + (0 - this.state.current) * 144 + "px)",
              }}
            >
              {this.props.tabs.map((tab, i) => (
                <button
                  type="button"
                  className={
                    this.props.activeTab === i
                      ? "tabs--btn tabs--btn-active bold"
                      : "tabs--btn regular"
                  }
                  key={i}
                >
                  <span
                    className="tabs--btnText"
                    onClick={() => this.props.setActiveTab(i)}
                  >
                    Визит&nbsp;{i + 1}
                  </span>
                  <img
                    src={IMAGES.thin.copy}
                    alt=""
                    onClick={() => this.props.copyVisit(i)}
                  />
                  {this.props.tabs.length > 1 ? (
                    <img
                      src={IMAGES.thin.bin}
                      alt=""
                      onClick={() => this.handleOpenDeleteModal(i)}
                    />
                  ) : (
                    ""
                  )}
                  {i > 0 && (
                    <img
                      src={IMAGES.constructor.bell}
                      onClick={() => this.handleOpenNotifyModal(i)}
                      alt=""
                    />
                  )}
                </button>
              ))}
            </div>
            {this.tabsBar.current && this.tabsTrack.current ? (
              this.tabsBar.current.offsetWidth <
              this.tabsTrack.current.offsetWidth ? (
                <div className="tabs--bar--control">
                  <button
                    className="tabs--bar--controlButton"
                    type="button"
                    onClick={() => this.tabsMove(-1)}
                    style={{
                      opacity: this.props.activeTab === 0 ? 0.5 : null,
                      cursor: this.props.activeTab === 0 ? "default" : null,
                    }}
                  >
                    <img src={IMAGES.constructor.arrow} alt="" />
                  </button>
                  <button
                    className="tabs--bar--controlButton tabs--bar--controlButton__right"
                    type="button"
                    onClick={() => this.tabsMove(1)}
                    style={{
                      opacity:
                        this.props.activeTab >= this.state.count - 1
                          ? 0.5
                          : null,
                      cursor:
                        this.props.activeTab >= this.state.count - 1
                          ? "default"
                          : null,
                    }}
                  >
                    <img src={IMAGES.constructor.arrow} alt="" />
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <button
            type="button"
            className="tabs--btn--new regular"
            onClick={this.props.addVisit}
          >
            <img src={IMAGES.icons.plus} width="10" height="11" alt="" />
            <span className="btn--text">Добавить</span>
          </button>
          <DeleteItemModal
            open={this.state.isDeleteModalOpen}
            onClose={this.handelCloseModal}
            onDelete={this.handleDelete}
            title="Удалить визит"
            body="Вы действительно хотите удалить визит?"
          />
        </div>
      </>
    );
  }
}

export default Tabs;

import { useState, type FC } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import IMAGES from "../../images/iconSet";

interface VisitNotifyModalProps {
  opened: boolean;
  onClose: () => void;
  visitIndex: number;
  prevVisitDateItems?: { id: string; options: { title: string } }[] | null;
}

const VisitNotifyModal: FC<VisitNotifyModalProps> = ({
  opened,
  onClose,
  visitIndex,
  prevVisitDateItems,
}) => {
  const [daysCount, setDaysCount] = useState(0);

  return (
    <Modal show={opened} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Настройка уведомлений Визит {visitIndex + 1}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Дата начала отсчета в прошлом визите</Form.Label>
            <Form.Select>
              {prevVisitDateItems?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.options.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Уведомить через</Form.Label>
            <div className="d-flex align-items-center gap-2">
              <Form.Control
                value={daysCount}
                onChange={(e) => setDaysCount(Number(e.target.value))}
                style={{ width: "70px" }}
                type="number"
              />
              <span>дней</span>
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Сохранить</Button>
        <Button
          variant="danger"
          style={{
            borderRadius: "19px",
            background: "#FF837B",
            borderColor: "#FF837B",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontSize: "14px",
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <img src={IMAGES.constructor.trash} alt="" />
          Удалить
        </Button>{" "}
      </Modal.Footer>
    </Modal>
  );
};

export default VisitNotifyModal;
